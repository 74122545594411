import React, { useEffect, useState } from "react";
import socket from "../Features/socket";
import {
  convertDateTimeUTCToLocal,
  getMatchDetails,
  getMatchType,
  teamRunnerSocket,
} from "../utilities/functions";
import { CONNECT, EVENT_DATA, EVENT_RUNNER_DATA, LIVE, RESULT } from "../utilities/const";
import { useNavigate } from "react-router-dom";

// Common component for score card
const ScoreCard = ({ cardData, removeCard }) => {
  const navigate = useNavigate();

  const [scoreData, setScoreData] = useState({
    ...cardData,
    url: convertDateTimeUTCToLocal(cardData?.utc).localDate.replace(/\//g, "-").toLowerCase() + "/" + cardData?.com.replace(/\s+/g, "-").toLowerCase() + "/" + cardData?.en.replace(/\s+/g, "-").toLowerCase(),
    t1MatchDetails: getMatchDetails(cardData?.t1s),
    t2MatchDetails: getMatchDetails(cardData?.t2s),
  });
  const [team1Score, setTeam1Score] = useState({});
  const [team2Score, setTeam2Score] = useState({});
  const [isTestMatch, setIsTestMatch] = useState(false);
  const [marketRunner, setMarketRunner] = useState(null);
  const [marketRunnerData, setMarketRunnerData] = useState(null);

  useEffect(() => {
    if (cardData?.tsi?.length > 0) {
      const oldInnings = cardData?.tsi.find((i) => i.inning === 1);
      if (oldInnings) {
        setIsTestMatch(true);
        setTeam1Score(getMatchDetails(oldInnings?.t1s));
        setTeam2Score(getMatchDetails(oldInnings?.t2s));
      }
    }
  }, [cardData?.tsi]);

  const configSocket = () => {
    socket?.on(EVENT_DATA, (data) => {
      const eventData = data?.value;
      if (data?.eventId === scoreData?.eid && eventData) {
        if (getMatchType(eventData?.cm?.cst) === RESULT) {
          removeCard(scoreData?.eid);
        }
        setScoreData({
          ...scoreData,
          cst: eventData?.cm?.cst,
          t1MatchDetails: eventData?.cm?.t1s
            ? getMatchDetails(eventData?.cm?.t1s)
            : {},
          t2MatchDetails: eventData?.cm?.t2s
            ? getMatchDetails(eventData?.cm?.t2s)
            : {},
          rmk: eventData?.cm?.rmk || "",
          rrr: eventData?.cm?.rrr || 0,
          crr: eventData?.cm?.crr || 0,
          t1bg: eventData?.cm?.t1bg,
          t1co: eventData?.cm?.t1co,
          t2bg: eventData?.cm?.t2bg,
          t2co: eventData?.cm?.t2co,
          ballid: eventData?.cm?.ballid,
          batid: eventData?.cm?.batid,
          t1id: eventData?.cm?.t1id,
          t2id: eventData?.cm?.t2id,
          mr: eventData?.mr,
          // loc: eventData?.cm?.loc || "",
        });
        if (eventData?.cm?.tsi.length > 0) {
          const oldInnings = eventData?.cm?.tsi.find((i) => i.inning === 1);
          if (oldInnings) {
            setIsTestMatch(true);
            setTeam1Score(getMatchDetails(oldInnings?.t1s));
            setTeam2Score(getMatchDetails(oldInnings?.t2s));
          }
        }
      }
    });
  };

  const runnerSocket = () => {
    socket?.on(EVENT_RUNNER_DATA, (data) => {        
      const eventRunnerData = data?.value;
      if (data?.eventId === scoreData?.eid && eventRunnerData) {
        const marketRunner = eventRunnerData[0]?.runners;
        setMarketRunnerData(marketRunner)
      }
    });
  }

  useEffect(()=>{
  if(marketRunnerData?.length > 0){
    const team1runner = teamRunnerSocket(marketRunnerData, scoreData?.t1id, scoreData?.te1n);
    const team2runner = teamRunnerSocket(marketRunnerData, scoreData?.t2id, scoreData?.te2n);
    const draw = marketRunnerData?.length > 0 ? marketRunnerData.find((item)=> item.runner == "The Draw") : null;
    
    if(draw && draw?.backPrice < 2){
      setMarketRunner({...draw, teamName: "Draw"});
    } else if(team1runner?.backPrice < team2runner?.backPrice || team1runner?.backPrice < 2){
      setMarketRunner(team1runner);
    } else if(team2runner?.backPrice < team1runner?.backPrice || team2runner?.backPrice < 2){
      setMarketRunner(team2runner);
    }
  }
  },[marketRunnerData, scoreData?.t1id, scoreData?.t2id, scoreData?.te1n, scoreData?.te2n]);

  // useEffect(()=>{
  //   if(scoreData?.mr?.length > 0){
  //     const marketRunnerValue = scoreData?.mr;
      
  //     const team1runner = teamRunner(marketRunnerValue, scoreData?.t1id, scoreData?.te1n);
  //     const team2runner = teamRunner(marketRunnerValue, scoreData?.t2id, scoreData?.te2n);
  //     const draw = marketRunnerValue.find((item)=>item.runner == "The Draw");
      
  //     if(draw && draw?.backPrice < 2){
  //       setMarketRunner({...draw, teamName: "Draw"});
  //     } else if(team1runner?.backPrice < team2runner?.backPrice || team1runner?.backPrice < 2){
  //       setMarketRunner(team1runner);
  //     } else if(team2runner?.backPrice < team1runner?.backPrice || team2runner?.backPrice < 2){
  //       setMarketRunner(team2runner);
  //     }
  //   }
  // },[scoreData?.mr]);
  
  useEffect(() => {
    if (socket) {
      if (socket?.connected) {
        configSocket();
      } else {
        socket?.on(CONNECT, () => {
          configSocket();
        });
      }
    }
  }, [scoreData?.eid]);

  useEffect(() => {
    if (socket) {
        if (socket?.connected) {
          runnerSocket();
        } else {
            socket?.on(CONNECT, () => {
              runnerSocket();
            });
        }
    }
  }, [scoreData?.eid])

  const handleGetDetails = () => {
    navigate("/full-score/" + cardData.eid + "/" + scoreData?.url);
    window.scrollTo(0, 0);
  };

  return (
    <div
      role="button"
      class="owl-item active"
      onClick={handleGetDetails}
      style={{ paddingTop: "12px" }}
    >
      <div className="score-card p-0" style={{borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"}}>
        <div className="scorecard-tag">
          {" "}
          <span>{getMatchType(scoreData?.cst)}</span>
        </div>
        <div className="scorecard-score">
        <div className="score-card-inner">
          <div className="score-card-header d-flex justify-content-between mt-4 mb-2">
            <div>
              {" "}
              <strong>{scoreData?.en}</strong>{" "}
            </div>
            {/* <strong>{getMatchType(scoreData?.cst)}</strong>
                    <span>{scoreData?.com}</span> */}
            <div>
              {" "}
              <strong>
                {convertDateTimeUTCToLocal(scoreData?.utc).localDate}{" "}
                {convertDateTimeUTCToLocal(scoreData?.utc).localTime}
              </strong>{" "}
            </div>
          </div>
          <div className="score-card-body-1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-45 align-items-center justify-content-start">
                <img
                  className="team-logo1"
                  src={scoreData?.te1i || "/assets/images/flag.png"}
                  alt=""
                  onError={(e) => {
                    e.target.src = "/assets/images/flag.png";
                  }}
                />
                <div className="score1">
                  <div
                    className="team1-score"
                    style={{
                      background: `transparent linear-gradient(270deg, ${scoreData.t1co}, #040404) 0 0 no-repeat padding-box`,
                      border: `4px solid ${scoreData.t1bg}`,
                    }}
                  >
                    <span className="bungee-regular">
                      {scoreData.t1MatchDetails?.runs}-
                      {scoreData.t1MatchDetails?.wickets}
                    </span>
                  </div>
                  <div
                    className="team1"
                    style={{
                      background: `${scoreData.t1bg} 0 0 no-repeat padding-box`,
                    }}
                  >
                    <span className="bungee-regular" style={{color : `${scoreData.t1co}`}}>
                      {parseFloat(scoreData.t1MatchDetails?.overs).toFixed(1)} ov
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-45 align-items-center justify-content-end">
                <img
                  className="team-logo2"
                  src={scoreData?.te2i || "/assets/images/flag.png"}
                  alt=""
                  onError={(e) => {
                    e.target.src = "/assets/images/flag.png";
                  }}
                />
                <div className="score2">
                  <div
                    className="team2-score"
                    style={{
                      background: `transparent linear-gradient(89deg, ${scoreData.t2co}, #040404) 0 0 no-repeat padding-box`,
                      border: `4px solid ${scoreData.t2bg}`,
                    }}
                  >
                    <span className="bungee-regular">
                      {scoreData.t2MatchDetails?.runs}-
                      {scoreData.t2MatchDetails?.wickets}
                    </span>
                  </div>
                  <div
                    className="team2"
                    style={{
                      background: `${scoreData.t2bg} 0 0 no-repeat padding-box`,
                    }}
                  >
                    <span className="bungee-regular" style={{color : `${scoreData.t2co}`}}>
                      {parseFloat(scoreData.t2MatchDetails?.overs).toFixed(1)} ov
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between my-2 flag-avatar">
              <div className="d-flex align-items-center">
                <span className="country-name">{scoreData?.te1n}</span>
                {(scoreData?.t1id && scoreData?.ballid) && scoreData?.t1id == scoreData?.ballid && <img className="bowl-logo" src={"/assets/images/bowling-old.png"} alt="" />}
                {(scoreData?.t1id && scoreData?.batid) && scoreData?.t1id == scoreData?.batid && <img className="bat-logo" src={"/images/bat.png"} alt="" />}
              </div>
              <div className="d-flex align-items-center">
                {(scoreData?.t2id && scoreData?.ballid) && scoreData?.t2id == scoreData?.ballid && <img className="bowl-logo" src={"/assets/images/bowling-old.png"} alt="" />}
                {(scoreData?.t2id && scoreData?.batid) && scoreData?.t2id == scoreData?.batid && <img className="bat-logo" src={"/images/bat.png"} alt="" />}
                <span className="country-name">{scoreData?.te2n}</span>
              </div>
            </div>
            {/* <div className="score-card-body">
                    <div className="country-info">
                        <div className="flag-avatar">
                            <figure>
                                <img src={scoreData?.te1i || "/assets/images/flag.png"} alt="" onError={(e) => { e.target.src = "/assets/images/flag.png"; }} />
                            </figure>
                            <span className="country-name">{scoreData?.s1n}</span>
                        </div>
                        <div className="score-update">
                            <h5>{scoreData.t1MatchDetails?.runs}/{scoreData.t1MatchDetails?.wickets}</h5>
                            <p className="text-muted">{parseFloat(scoreData.t1MatchDetails?.overs).toFixed(1)}</p>

                            {isTestMatch ? 
                            <p className='mt-1' style={{ color: "black"}}>
                              & {team1Score?.runs}/{team1Score?.wickets} ({parseFloat(team1Score?.overs).toFixed(1)})
                            </p>
                            : null}
                        </div>
                        //commented
                        {isTestMatch ?
                            <div className="score-update">
                                <h5>{team1Score?.runs}/{team1Score?.wickets}</h5>
                                <p className="text-muted">{parseFloat(team1Score?.overs).toFixed(1)}</p>
                            </div>
                        : null}
                    </div>
                    <div class="score-update m-0 text-center">
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <strong>{scoreData?.loc}</strong>
                      </div>
                    </div>
                    <div className="country-info flex-row-reverse">
                        <div className="flag-avatar ml-05">
                            <figure>
                                <img src={scoreData?.te2i || "/assets/images/flag.png"} alt="" onError={(e) => { e.target.src = "/assets/images/flag.png"; }} />
                            </figure>
                            <span className="country-name">{scoreData?.s2n}</span>
                        </div>
                        <div className="score-update text-right">
                            <h5>{scoreData.t2MatchDetails?.runs}/{scoreData.t2MatchDetails?.wickets}</h5>
                            <p className="text-muted">{parseFloat(scoreData.t2MatchDetails?.overs).toFixed(1)}</p>

                            {isTestMatch ? 
                            <p className='mt-1' style={{ color: "black"}}>
                              {team2Score?.runs}/{team2Score?.wickets} ({parseFloat(team2Score?.overs).toFixed(1)}) &
                            </p>
                            : null}
                        //commented
                        {isTestMatch ?
                            <div className="score-update">
                                <h5>{team2Score?.runs}/{team2Score?.wickets}</h5>
                                <p className="text-muted">{parseFloat(team2Score?.overs).toFixed(1)}</p>
                            </div>
                        : null}
                        </div>
                    </div>
                </div> */}
            {/* <span className="tournament">
              {scoreData?.com}
            </span> */}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
        <span className="tournament top-card">
          {scoreData?.com}
        </span>
        {(marketRunnerData?.length > 0 && (parseFloat(marketRunner?.backPrice || 0) || parseFloat(marketRunner?.layPrice || 0))) ?
        <div className="d-flex align-items-center">
              <div className="runner-team-card">{marketRunner?.teamName}</div>
              <div className="d-flex align-items-center mx-2">
                  <div className="back-price-card back-price-radius px-2">{marketRunner?.backPrice}</div>
                  <div className="lay-price-card lay-price-radius px-2">{marketRunner?.layPrice}</div>
              </div>
        </div>: null}
        </div>
        </div>
        {/* <div className="score-card-footer">
          <div className="d-flex align-items-center justify-content-between">
              <div className="runner-team mr-2" style={{ background: `${scoreData?.t1bg}`}}>{scoreData?.te1n}</div>
              <div className="d-flex align-items-center mx-2">
                  <div className="lay-price mr-1">10</div>
                  <div className="back-price">20</div>
              </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ScoreCard;
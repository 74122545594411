import React, { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import DynamicRoutesSkeleton from "../Components/Skeleton/DynamicRoutesSkeleton";

const DynamicRoutes = ({ alias }) => {
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchPagesContent = async () => {
    try {
      const response = await axiosInstance.post(
        `/admin/menuItem/getPageContent`,
        {
          alias,
        }
      );
      const pageContent = response.data?.result;
      setPageData(pageContent);
    } catch (error) {
      console.error("Error fetching page content:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPagesContent();
  }, [alias]);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-SCVQXEDJ8K`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-SCVQXEDJ8K');
  }, [alias]);

  useEffect(() => {
    if (pageData) {
      // document title
      document.title = pageData?.pageTitle || '';

      // meta title
      const metaTitle = document.querySelector('meta[name="title"]');
      if (metaTitle) {
        metaTitle.content = pageData?.pageTitle || 'Title';
      } else {
        const newMetaTitle = document.createElement('meta');
        newMetaTitle.name = "title";
        newMetaTitle.content = pageData?.pageTitle || 'Title';
        document.head.appendChild(newMetaTitle);
      }

      // meta description
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.content = pageData?.seoDescription || 'description';
      } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.name = "description";
        newMetaDescription.content = pageData?.seoDescription || 'description';
        document.head.appendChild(newMetaDescription);
      }
    }
  }, [pageData]);
  
  return (
    <>
        {loading ? (
          <DynamicRoutesSkeleton />
        ) : (
          <div class="col-lg-9">
            <section class="live-matches pt-0">
              <div class="card card-shadow">
                {/* {pageData?.pageHeading} */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.pageContent,
                  }}
                />
              </div>
            </section>
          </div>
        )}
    </>
  );
};

export default DynamicRoutes;

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppContext } from '../../Context/AppContext';

const QuickLinks = () => {
    const { cmsData } = useAppContext();
    const [menuItem, setMenuItem] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (cmsData?.menuItems?.length > 0) {
          const footer = cmsData.menuItems.find(item => item?.menuTypeName?.toLowerCase() === "footer");
          setMenuItem(footer?.menuItem || []);
          setLoading(false);
        }
        setLoading(false);
    }, [cmsData?.menuItems]);

    return (
        <div className="foo-widget foo-navigation">
        <h3 className="widget-title">Quick Links</h3>
        {loading ? (
            <p>Loading...</p>
        ) : (
            <ul>
                {menuItem.length > 0 ? (
                    menuItem.map(item => (
                        <li key={item?.menuItemId}>
                            <Link to={item?.pageDetails?.alias}>{item.menuItem}</Link>
                        </li>
                    ))
                ) : null}
            </ul>
        )}
    </div>
    )
}

export default QuickLinks
import React, { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppContext } from "../Context/AppContext";
import FullScore from "../Pages/FullScore";
import Scoreboard from "../Pages/Scoreboard";
import DynamicRoutes from "../Pages/DynamicRoutes";
import News from "../Pages/News";
import NewsDetails from "../Pages/NewsDetails";
import Scoreboard2 from "../Pages/Scoreboard2";
import Login from "../Pages/Login";
import Register from "../Pages/Register";
import VerifyOtp from "../Pages/VerifyOtp";
import SetPassword from "../Pages/SetPassword";
import ForgetPassword from "../Pages/ForgetPassword";
import SetNewPassword from "../Pages/SetNewPassword";
import Profile from "../Pages/Profile";
import VerifyEmail from "../Pages/VerifyEmail";
import VerifyMobile from "../Pages/VerifyMobile";
import AppLayout from "./AppLayout";
import MatchMainSection from "../Components/MatchMainSection";
import BannerDetailsSection from "../Components/BannerDetailsSection";

const PublicRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const [liveUpcomingData, setLiveUpcomingData] = useState({});
  const [cmsData, setCmsData] = useState({})
  const fetchMenuItem = async () => {
    try {
      const response = await axiosInstance.post(`/admin/menuItem/getCMSV1`);
      setCmsData(response?.data?.result);
      const pagesList = response.data?.result?.pages;
      setRoutes(pagesList);
    } catch (error) {
      console.error("Error fetching Menu Items:", error);
    }
  };

  const fetchLiveUpcomingMatchData = async () => {
    try {
      const response = await axiosInstance.post(`/admin/match/liveScheduleMatchV1`);
      const result = response?.data?.result;
      setLiveUpcomingData(result);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchMenuItem();
    fetchLiveUpcomingMatchData();
  }, []);

  return (
    <>
      <BrowserRouter>
       <AppContext.Provider value={{ cmsData, liveUpcomingData }}> 
        <AppLayout>
          <Routes>
            <Route exact path="/" element={<MatchMainSection pageTitle={"Home"}/>} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/verify-email" element={<VerifyEmail />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/verifyOtp" element={<VerifyOtp />} />
            <Route exact path="/verifyMobile" element={<VerifyMobile />} />
            <Route exact path="/forgetPassword" element={<ForgetPassword />} />
            <Route exact path="/setPassword" element={<SetPassword />} />
            <Route exact path="/setNewPassword" element={<SetNewPassword />} />
            <Route exact path="/full-score/:id/*" element={<FullScore />} />
            <Route exact path="/news" element={<News />} />
            <Route exact path="/news/:id/*" element={<NewsDetails />} />
            <Route exact path="/banner/:id/*" element={<BannerDetailsSection />} />
            <Route exact path="/scoreboard" element={<Scoreboard />} />
            <Route exact path="/scoreboard2" element={<Scoreboard2 />} />
            {routes?.map((page) => {
              if (page.pageId === '356155b70778431dbfacf5ac98649a5e') {
               return (
                <Route
                  key={page.pageId}
                  path={page.alias}
                  element={<MatchMainSection pageTitle={page?.pageTitle} />}
                />
              );
            }
              return (
               <Route
                 key={page.pageId}
                 path={page.alias}
                 element={<DynamicRoutes alias={page.alias} />}
               />
              );
            })}
          </Routes>
        </AppLayout>
       </AppContext.Provider>
      </BrowserRouter>
    </>
  );
};

export default PublicRoutes;

import { useEffect, useState } from "react";
import FullScoreContent from "./FullScoreContent";
import LiveTeams from "./LiveTeams";
import ScoreCardDetails from "./ScoreCardDetails";
import CommentaryDetails from "./CommentaryDetails";
import PartnershipDetails from "./PartnershipDetails";
import LiveScoreboard from "./LiveScoreboard";
import Overs from "./Overs";
import axiosInstance from "../Features/axios";
import { Link, useParams } from "react-router-dom";
import socket from "../Features/socket";
import { COMMENTARY_CONNECTION, COMMENTARY_DISCONNECTION, CONNECT, EVENT_DATA, EVENT_RUNNER_DATA, FULLSCORE_CONNECTION, FULLSCORE_DATA, LIVE, MATCH_SECTIONS, RESULT, RUNNER_CONNECTION } from "../utilities/const";
import { getCurrentOver, getMatchDetails, getMatchType, getOvers, teamRunnerSocket } from "../utilities/functions";
import FullScoreContentSkeleton from "./Skeleton/FullScoreContentSkeleton";
import FullscoreChart from "./FullscoreChart";

const getTeamData = (teams, currentBatTeamId) => {
  let batTeams = []
  let bowlTeams = []
  teams?.forEach(team => {
    if (team.batOrd) {
      if (team.isbc) {
        batTeams.push(team)
        bowlTeams.push(team)
      } else if (team.tid === currentBatTeamId) {
        batTeams.push(team)
        if (teams.findIndex(i => i.cci === team.cci && i.isbc) !== -1) {
          bowlTeams.push(team)
        }
      } else {
        bowlTeams.push(team)
      }
    }
  })
  batTeams = batTeams.sort((a, b) => {
    // First, sort by cci in descending order
    if (a.cci < b.cci) return 1;
    if (a.cci > b.cci) return -1;

    // If cci is the same, sort by batOrd in descending order
    if (a.batOrd < b.batOrd) return 1;
    if (a.batOrd > b.batOrd) return -1;

    return 0; // if both cci and batOrd are equal, maintain original order
  });
  bowlTeams = bowlTeams.sort((a, b) => {
    // First, sort by cci in descending order
    if (a.cci < b.cci) return 1;
    if (a.cci > b.cci) return -1;

    // If cci is the same, sort by batOrd in descending order
    if (a.batOrd > b.batOrd) return 1;
    if (a.batOrd < b.batOrd) return -1;

    return 0; // if both cci and batOrd are equal, maintain original order
  });
  return { batTeams, bowlTeams }
}

const getSocketTeamData = (teams, currentBatTeamId) => {
  let batTeams = []
  let bowlTeams = []
  teams?.forEach(team => {
    if (team.teamBattingOrder) {
      if (team.isBattingComplete) {
        batTeams.push(team)
        bowlTeams.push(team)
      } else if (team.teamId === currentBatTeamId) {
        batTeams.push(team)
        if (teams.findIndex(i => i.currentInnings === team.currentInnings && i.isBattingComplete) !== -1) {
          bowlTeams.push(team)
        }
      } else {
        bowlTeams.push(team)
      }
    }
  })
  batTeams = batTeams.sort((a, b) => {
    // First, sort by currentInnings in descending order
    if (a.currentInnings < b.currentInnings) return 1;
    if (a.currentInnings > b.currentInnings) return -1;

    // If currentInnings is the same, sort by teamBattingOrder in descending order
    if (a.teamBattingOrder < b.teamBattingOrder) return 1;
    if (a.teamBattingOrder > b.teamBattingOrder) return -1;

    return 0; // if both currentInnings and teamBattingOrder are equal, maintain original order
  });
  bowlTeams = bowlTeams.sort((a, b) => {
    // First, sort by currentInnings in descending order
    if (a.currentInnings < b.currentInnings) return 1;
    if (a.currentInnings > b.currentInnings) return -1;

    // If currentInnings is the same, sort by teamBattingOrder in descending order
    if (a.teamBattingOrder > b.teamBattingOrder) return 1;
    if (a.teamBattingOrder < b.teamBattingOrder) return -1;

    return 0; // if both currentInnings and teamBattingOrder are equal, maintain original order
  });
  return { batTeams, bowlTeams }
}
const teamScoreData = (teamData) => {
  let data =  `${teamData?.teamScore ? teamData.teamScore : 0}/${teamData?.teamWicket ? teamData.teamWicket : 0}(${teamData?.teamOver ? teamData.teamOver : 0})`
  return data
}
const FullScoreMainSection = () => {
  const [showState, setShowState] = useState(MATCH_SECTIONS.LIVE);
  const paramsData = useParams();
  const [scoreData, setScoreData] = useState(undefined);
  const [scoreCardData, setScoreCardData] = useState(undefined);
  const [commentaryData, setCommentaryData] = useState(undefined);
  const [teamData, setTeamData] = useState(undefined);
  const [partnershipData, setPartnersipData] = useState(undefined);
  const [commentaryOverData, setCommentaryOverData] = useState(undefined);
  const [oversData, setOversData] = useState(undefined);
  const [graphData, setGraphData] = useState(undefined);
  const [isShowClient, setIsShowClient] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [isChase, setIsChase] = useState(false);
  const [currentDetailsData, setCurrentDetailsData] = useState({});
  const [currentTeamsData, setCurrentTeamsData] = useState([]);
  const [currentPlayersData, setCurrentPlayersData] = useState([]);
  const [currentBallByBallData, setCurrentBallByBallData] = useState([]);
  const [currentWicketData, setCurrentWicketData] = useState([]);
  const [currentOversData, setCurrentOversData] = useState([]);
  const [currentPartnershipData, setCurrentPartnershipData] = useState([]);
  const [marketOddsBallByBallData, setMarketOddsBallByBallData] = useState([]);
  const [allCommentaryInnings, setAllCommentaryInnings] = useState({});
  const [runnerData, setRunnerData] = useState(null);
  const [marketRunnerData, setMarketRunnerData] = useState(null);
  // const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);
  
  // const fetchFullScoreData = async (eventId) => {
    // try {
    //   const response = await axiosInstance.post(
    //     `/admin/match/getfullScoreCard`,
    //     {
    //       eventId,
    //     }
    //   );
    //   const fullScoreCardResult = response.data?.result;
    //   const scoreData = fullScoreCardResult?.es || {};
    //   const matchStatus = getMatchType(scoreData?.cst);

    //   if (matchStatus === LIVE) {
    //     fetchScoreData(paramsData.id);
    //   }
    //   setIsShowClient(scoreData?.ics);
    //   setInProgress(matchStatus === LIVE)
    //   setShowState(matchStatus === LIVE ? MATCH_SECTIONS.LIVE : MATCH_SECTIONS.SCORECARD);
      // setScoreData({
      //   ...scoreData,
      //   t1n: scoreData?.te1n,
      //   t2n: scoreData?.te2n,
      //   t1im: scoreData?.te1i,
      //   t2im: scoreData?.te2i,
      //   t1sn: scoreData?.s1n,
      //   t2sn: scoreData?.s2n,
      //   t1MatchDetails: scoreData?.t1s ? getMatchDetails(scoreData?.t1s) : {},
      //   t2MatchDetails: scoreData?.t2s ? getMatchDetails(scoreData?.t2s) : {},
      // });

      // const crrentBowlingTeam = fullScoreCardResult?.td?.find(team => scoreData?.baid === team?.tid && scoreData?.cci === team?.cci)
      // setIsChase(crrentBowlingTeam?.batOrd === 2)
      // const separatedArrays = fullScoreCardResult?.ov && fullScoreCardResult.ov.reduce((acc, obj) => {
      //   const { tid } = obj;
      //   if (!acc[tid]) {
      //     acc[tid] = [];
      //   }
      //   acc[tid].push(obj);
      //   return acc;
      // }, {});
      // const { batTeams, bowlTeams } = getTeamData(fullScoreCardResult?.td, fullScoreCardResult?.es?.baid)
      // setOversData({
      //   overs: separatedArrays,
      //   teamsData: fullScoreCardResult?.td,
      //   players: [...fullScoreCardResult?.Sqt1, ...fullScoreCardResult?.Sqt2],
      //   summery: fullScoreCardResult?.es,
      // });
      // if (fullScoreCardResult) {
        // setScoreCardData({
        //   td: batTeams,
        //   es: fullScoreCardResult?.es,
        //   te1n: fullScoreCardResult?.es?.te1n,
        //   te2n: fullScoreCardResult?.es?.te2n,
        //   t1s: fullScoreCardResult?.es?.t1s,
        //   t2s: fullScoreCardResult?.es?.t2s,
        //   cci1: fullScoreCardResult?.cci1,
        //   cci2: fullScoreCardResult?.cci2,
        //   sqt1: fullScoreCardResult?.Sqt1,
        //   sqt2: fullScoreCardResult?.Sqt2,
        // });
        // setTeamData({
        //   te1n: fullScoreCardResult?.es?.te1n,
        //   te2n: fullScoreCardResult?.es?.te2n,
        //   t1jr: fullScoreCardResult?.es?.t1jr,
        //   t2jr: fullScoreCardResult?.es?.t2jr,
        //   sqt1: fullScoreCardResult?.Sqt1,
        //   sqt2: fullScoreCardResult?.Sqt2,
        //   t1img: fullScoreCardResult?.es?.te1i,
        //   t2img: fullScoreCardResult?.es?.te2i
        // });
        // setPartnersipData({
        //   par: fullScoreCardResult?.par,
        //   t1jr: fullScoreCardResult?.es?.t1jr,
        //   t2jr: fullScoreCardResult?.es?.t2jr,
        //   td: batTeams
        // });
        // setCommentaryOverData({
        //   td: bowlTeams,
        //   ov: fullScoreCardResult?.ov,
        //   t1s: fullScoreCardResult?.es?.t1s,
        //   t2s: fullScoreCardResult?.es?.t2s,
        //   sqt1: fullScoreCardResult?.Sqt1,
        //   sqt2: fullScoreCardResult?.Sqt2,
        //   t1id: fullScoreCardResult?.es?.t1id,
        //   t2id: fullScoreCardResult?.es?.t2id
        // });
        // setIsError(false)
      // }
    // } catch (error) {
    //   console.log("error", error);
    //   // setIsError(true)
    // } finally {
    //   setLoading(false);
    // }
  // };

  // const fetchScoreData = async (eventId) => {
  //   try {
  //     const response = await axiosInstance.post(`/admin/match/getscoreByEId`, {
  //       eventId,
  //     });
  //     const eventData = response?.data?.result;
  //     if (eventData) {
  //       const scoreData = eventData?.cm;
  //       setScoreData({
  //         ...scoreData,
  //         t1MatchDetails: scoreData?.t1s ? getMatchDetails(scoreData?.t1s) : {},
  //         t2MatchDetails: scoreData?.t2s ? getMatchDetails(scoreData?.t2s) : {},
  //         rmk: scoreData?.rmk || "",
  //         crr: scoreData?.crr || 0,
  //         res: scoreData?.res,
  //         cst: scoreData?.cst,
  //         tpp1: scoreData?.tpp1,
  //         tpp2: scoreData?.tpp2,
  //       });
  //       setCommentaryData({
  //         ...eventData,
  //         currOver: getCurrentOver(scoreData?.scov, eventData?.cbb),
  //       });
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-SCVQXEDJ8K`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-SCVQXEDJ8K');
  }, []);

  const fetchFullScoreCardByEId = async (eventId) => {
    try {
      const response = await axiosInstance.post(`/admin/match/getfullScoreCardByEId`, {
        eventId,
      });
      // console.log("response",response?.data?.result);
      const fullscoreData = response?.data?.result?.value;

     if(response?.data?.result?.eventId === eventId && fullscoreData) {
      const details = fullscoreData?.find(
        (item) => item.module === "commentaryDetails"
      );
      const teams = fullscoreData?.find(
        (item) => item.module === "commentaryTeams"
      );
      const players = fullscoreData?.find(
        (item) => item.module === "commentaryPlayers"
      );
      const ballByBall = fullscoreData?.find(
        (item) => item.module === "commentaryBallByBall"
      );
      const wicket = fullscoreData?.find(
        (item) => item.module === "commentaryWicket"
      );
      const overs = fullscoreData?.find(
        (item) => item.module === "commentaryOvers"
      );
      const partnership = fullscoreData?.find(
        (item) => item.module === "commentaryPartnership"
      );
      const marketOddsBallByBall = fullscoreData?.find(
        (item) => item.module === "marketOddsBallByBall"
      );
      const matchStatus = getMatchType(details?.data?.commentaryStatus);
      // if (matchStatus === LIVE) {
      //   fetchScoreData(paramsData.id);
      // }
      // setIsShowClient(details?.data?.isClientShow);
      setShowState((matchStatus === LIVE && details?.data?.isClientShow) ? MATCH_SECTIONS.LIVE : matchStatus === LIVE ? MATCH_SECTIONS.COMMENTARY : matchStatus === "UPCOMING" ? MATCH_SECTIONS.TEAM : MATCH_SECTIONS.SCORECARD);
      if(matchStatus === "RESULT") {
      setLoading(false);
      setIsShowClient(details?.data?.isClientShow);
      setInProgress(matchStatus === LIVE)
      // setShowState(matchStatus === LIVE ? MATCH_SECTIONS.LIVE : MATCH_SECTIONS.SCORECARD);

      if(details?.data) {
        setCurrentDetailsData(details?.data);
      } 
      if(teams?.type === "full") {
        setCurrentTeamsData(teams?.data);
      }
      if(players?.type === "full") {
        setCurrentPlayersData(players?.data)
      }
      if(ballByBall?.type === "full"){
        setCurrentBallByBallData(ballByBall?.data)
      }
      if(wicket?.type === "full"){
        setCurrentWicketData(wicket?.data)
      }
      if(overs?.type === "full"){
        setCurrentOversData(overs?.data)
      }
      if(partnership?.type === "full"){
        setCurrentPartnershipData(partnership?.data)
      }
      if(marketOddsBallByBall?.type === "full"){
        setMarketOddsBallByBallData(marketOddsBallByBall?.data);
      }
      }
     }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (paramsData?.id) {
      setShowState(MATCH_SECTIONS.LIVE);
      // setInProgress(false)
      setPartnersipData(undefined);
      setOversData(undefined);
      setTeamData(undefined);
      setScoreCardData(undefined);
      setCommentaryOverData(undefined);
      setGraphData(undefined);
      // fetchFullScoreData(paramsData.id);
    }
  }, [paramsData?.id]);
  
  useEffect(() => {
    if (paramsData?.id) {
      window.scrollTo(0, 0);
      const fetchData = async () => {
        try {
          await fetchFullScoreCardByEId(paramsData.id);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [paramsData?.id]);

  const extractEventID = () => {
    const currentURL = window.location.href;

    const regex = /full-score\/(.*?)\//; // Matches text between "full-score/" and "/"
    const match = currentURL.match(regex);

    if (match && match.length > 1) {
      return match[1];
    } else {
      return null; // Return null if data not found in URL
    }
  };

  const configSocket = (eventId) => {
    socket?.emit(COMMENTARY_CONNECTION, [eventId]);
    socket?.on(EVENT_DATA, (data) => {
      const eventData = data?.value;
      // console.log("eventData", eventData);
      const eventIdpro = extractEventID();
      if (data?.eventId === eventIdpro && eventData) {
        const scoreData = eventData?.cm;
        // const marketRunner = eventData?.mr;
        // const team1runner = teamRunner(marketRunner, scoreData?.t1id, scoreData?.t1n);
        // const team2runner = teamRunner(marketRunner, scoreData?.t2id, scoreData?.t2n);
        // const draw = marketRunner?.length > 0 ? marketRunner.find((item)=> item.runner == "The Draw") : null;
        const matchStatus = getMatchType(scoreData?.cst);
        setInProgress(matchStatus === LIVE)
        // setShowState(matchStatus === LIVE ? showState : MATCH_SECTIONS.SCORECARD);
        if (matchStatus === RESULT) {
          socket?.emit(COMMENTARY_DISCONNECTION, [eventId]);
        }
        if(matchStatus !== RESULT){
        // setInProgress(matchStatus === LIVE)
        const currOver = getOvers(eventData?.cm?.scov, eventData?.cbb);
        setScoreData({
          ...scoreData,
          t1MatchDetails: scoreData?.t1s
            ? getMatchDetails(scoreData?.t1s)
            : {},
          t2MatchDetails: scoreData?.t2s
            ? getMatchDetails(scoreData?.t2s)
            : {},
          rmk: scoreData?.rmk || "",
          crr: scoreData?.crr || 0,
          res: scoreData?.res,
          cst: scoreData?.cst,
          tpp1: scoreData?.tpp1,
          tpp2: scoreData?.tpp2,
          loc: scoreData?.loc,
          tsi: scoreData?.tsi,
          ballid: scoreData?.ballid,
          batid: scoreData?.batid,
          t1id: scoreData?.t1id,
          t2id: scoreData?.t2id,
          currOver: currOver,
          dis: scoreData?.dis,
          win: scoreData?.win,
          // team1Runner: team1runner,
          // team2Runner: team2runner,
          // drawRunner: draw,
          mtyp: scoreData?.mtyp,
          battingTeam: eventData?.cm?.scot == eventData?.cm?.t1sn ? { bgColor: eventData?.cm?.t1bg, borderColor: eventData?.cm?.t1co } : { bgColor: eventData?.cm?.t2bg, borderColor: eventData?.cm?.t2co },
          bowlingTeam: eventData?.cm?.scot == eventData?.cm?.t2sn ? { bgColor: eventData?.cm?.t1bg, borderColor: eventData?.cm?.t1co } : { bgColor: eventData?.cm?.t2bg, borderColor: eventData?.cm?.t2co },
        });
        }
        setCommentaryData({
          ...eventData,
          currOver: getCurrentOver(scoreData?.scov, eventData?.cbb),
        });
      }
    });
  }
  
  const runnerSocket = (eventId) => {
    socket?.emit(RUNNER_CONNECTION, [eventId]);
    socket?.on(EVENT_RUNNER_DATA, (data) => {
      const eventRunnerData = data?.value;
      const eventIdpro = extractEventID();
      if (data?.eventId === eventIdpro && eventRunnerData) {
        const marketRunner = eventRunnerData[0]?.runners;
        setMarketRunnerData(marketRunner)
      }
    });
  }

  useEffect(()=>{
    const team1runner = teamRunnerSocket(marketRunnerData, scoreData?.t1id, scoreData?.t1n);
    const team2runner = teamRunnerSocket(marketRunnerData, scoreData?.t2id, scoreData?.t2n);
    const draw = marketRunnerData?.length > 0 ? marketRunnerData.find((item)=> item.runner == "The Draw") : null;
    setRunnerData({
      team1Runner: team1runner,
      team2Runner: team2runner,
      drawRunner: draw,
    })
  },[marketRunnerData, scoreData?.t1id, scoreData?.t2id, scoreData?.t1n, scoreData?.t2n]);

  const fullscoreConfigSocket = (eventId) => {
    socket?.emit(FULLSCORE_CONNECTION, [eventId]);
    socket?.on(FULLSCORE_DATA, (data) => {
      // console.log("Received Fullscore updated data:", data);
      const fullscoreData = data?.value;
      const eventIdpro = extractEventID();
      if(data?.eventId === eventIdpro && fullscoreData) {
      setLoading(false);
      const details = fullscoreData?.find(
        (item) => item.module === "commentaryDetails"
      );
      const teams = fullscoreData?.find(
        (item) => item.module === "commentaryTeams"
      );
      const players = fullscoreData?.find(
        (item) => item.module === "commentaryPlayers"
      );
      const ballByBall = fullscoreData?.find(
        (item) => item.module === "commentaryBallByBall"
      );
      const wicket = fullscoreData?.find(
        (item) => item.module === "commentaryWicket"
      );
      const overs = fullscoreData?.find(
        (item) => item.module === "commentaryOvers"
      );
      const partnership = fullscoreData?.find(
        (item) => item.module === "commentaryPartnership"
      );
      const marketOddsBallByBall = fullscoreData?.find(
        (item) => item.module === "marketOddsBallByBall"
      );
      // const matchStatusCheck = getMatchType(details?.data?.commentaryStatus);
      // if (matchStatus === LIVE) {
      //   fetchScoreData(paramsData.id);
      // }
      // setShowState(matchStatus === LIVE ? MATCH_SECTIONS.LIVE : MATCH_SECTIONS.SCORECARD);
      // if(matchStatusCheck === "LIVE" || matchStatusCheck === "UPCOMING") {
      if(details?.data) {
        const matchStatus = getMatchType(details?.data?.commentaryStatus);
        setInProgress(matchStatus === LIVE)
        setIsShowClient(details?.data?.isClientShow);
        // setShowState((matchStatus === LIVE && details?.data?.isClientShow) ? MATCH_SECTIONS.LIVE : matchStatus === LIVE ? MATCH_SECTIONS.COMMENTARY : MATCH_SECTIONS.SCORECARD);
        setCurrentDetailsData(details?.data);
      } 
      if (teams?.type === "full") {
        setCurrentTeamsData(teams?.data);
      } else if(teams?.type === "update") {
        setCurrentTeamsData((prevTeams) => {
          const updatedTeamsData = teams?.data;
          const newTeamsData = [...prevTeams];
          updatedTeamsData.forEach((updatedTeam) => {
            const index = newTeamsData.findIndex(team => team.commentaryTeamId === updatedTeam.commentaryTeamId);
            if (index !== -1) {
              newTeamsData[index] = updatedTeam;
            }
          });

          return newTeamsData;
        });
      }
      if(players?.type === "full") {
        setCurrentPlayersData(players?.data)
      } else if(players?.type === "update") {
        setCurrentPlayersData((prevPlayers) => {
          const updatedPlayersData = players?.data;
          const newPlayersData = [...prevPlayers];
          updatedPlayersData?.forEach((updatedPlayer) => {
            const index = newPlayersData.findIndex(player => player.commentaryPlayerId === updatedPlayer.commentaryPlayerId);
            if (index !== -1) {
              newPlayersData[index] = updatedPlayer;
            }
          });
          return newPlayersData;
        });
      }
      if(ballByBall?.type === "full"){
        setCurrentBallByBallData(ballByBall?.data)
      } else if(ballByBall?.type === "update") {
        setCurrentBallByBallData((prevBallByBall) => {
          const updatedBallByBallData = ballByBall?.data;
          const newBallByBallData = [...prevBallByBall];
          const index = newBallByBallData.findIndex(item => item.commentaryBallByBallId === updatedBallByBallData.commentaryBallByBallId);
          if (index !== -1) {
            newBallByBallData[index] = updatedBallByBallData;
          }
          return newBallByBallData;
        });
      } else if (ballByBall?.type === "create") {
        // setCurrentBallByBallData((prevBallByBall) => {
        //   const newBallByBallData = [...prevBallByBall, ballByBall?.data];
        //   return newBallByBallData;
        // });
        setCurrentBallByBallData((prevBallByBall) => {
          const existingIndex = prevBallByBall.findIndex(item => item.commentaryBallByBallId === ballByBall?.data.commentaryBallByBallId);
          if (existingIndex === -1) {
            return [...prevBallByBall, ballByBall?.data];
          } else {
            return prevBallByBall;
          }
      });
      } else if (ballByBall?.type === "delete") {
        setCurrentBallByBallData((prevBallByBall) => {
          const updatedBallByBallData = ballByBall?.data;
          const newBallByBallData = prevBallByBall.filter((item)=> item.commentaryBallByBallId !== updatedBallByBallData.commentaryBallByBallId);
          return newBallByBallData;
        });
        setCurrentWicketData((prevWicket) => {
          const updatedBallByBallData = ballByBall?.data;
          const newWicketData = prevWicket.filter((item)=> item.commentaryBallByBallId !== updatedBallByBallData.commentaryBallByBallId);
          return newWicketData;
        });
        setCurrentPartnershipData((prevPartnership) => {
          const updatedBallByBallData = ballByBall?.data;
          const newPartnershipData = prevPartnership.filter((item)=> item.commentaryBallByBallId !== updatedBallByBallData.commentaryBallByBallId);
          return newPartnershipData;
        });
      }
      if(wicket?.type === "full"){
        setCurrentWicketData(wicket?.data)
      } else if(wicket?.type === "update") {
        setCurrentWicketData((prevWicket) => {
          const updatedWicketData = wicket?.data;
          const newWicketData = [...prevWicket];
          const index = newWicketData.findIndex(item => item.commentaryWicketId === updatedWicketData.commentaryWicketId);
          if (index !== -1) {
            newWicketData[index] = updatedWicketData;
          }
          return newWicketData;
        });
      } else if (wicket?.type === "create") {
        // setCurrentWicketData((prevWicket) => {
        //   const newWicketData = [...prevWicket, wicket?.data];
        //   return newWicketData;
        // });
        setCurrentWicketData((prevWicket) => {
          const existingIndex = prevWicket.findIndex(item => item.commentaryWicketId === wicket?.data.commentaryWicketId);
          if (existingIndex === -1) {
            return [...prevWicket, wicket?.data];
          } else {
            return prevWicket;
          }
      });
      }
      if(overs?.type === "full"){
        setCurrentOversData(overs?.data)
      } else if(overs?.type === "update") {
        setCurrentOversData((prevOvers) => {
          const updatedOversData = overs?.data;
          const newOversData = [...prevOvers];
          const index = newOversData.findIndex(over => over.overId === updatedOversData.overId);
          if (index !== -1) {
            newOversData[index] = updatedOversData;
          }
          return newOversData;
        });
      } else if (overs?.type === "create") {
        // setCurrentOversData((prevOvers) => {
        //   const newOversData = [...prevOvers, overs?.data];
        //   return newOversData;
        // });
        setCurrentOversData((prevOvers) => {
          const existingIndex = prevOvers.findIndex(over => over.overId === overs?.data.overId);
          if (existingIndex === -1) {
            return [...prevOvers, overs?.data];
          } else {
            return prevOvers;
          }
        });
      } else if (overs?.type === "delete") {
        setCurrentOversData((prevOvers) => {
          const updatedOversData = overs?.data;
          const newOversData = prevOvers.filter((item)=> item.overId !== updatedOversData.overId);
          return newOversData;
        });
        setMarketOddsBallByBallData((prevMarket) => {
          const updatedOversData = overs?.data;
          const newMarketData = prevMarket.filter((item)=> item.overId !== updatedOversData.overId);
          return newMarketData;
        });
      }
      if(partnership?.type === "full"){
        setCurrentPartnershipData(partnership?.data)
      } else if(partnership?.type === "update") {
        setCurrentPartnershipData((prevPartnership) => {
          const updatedPartnershipData = partnership?.data;
          const newPartnershipData = [...prevPartnership];
          const index = newPartnershipData.findIndex(item => item.commentaryPartnershipId === updatedPartnershipData.commentaryPartnershipId);
          if (index !== -1) {
            newPartnershipData[index] = updatedPartnershipData;
          } /* else {
            newPartnershipData.push(updatedPartnershipData);
          } */
          return newPartnershipData;
        });
      } else if (partnership?.type === "create") {
        // setCurrentPartnershipData((prevPartnership) => {
        //   const newPartnershipData = [...prevPartnership, partnership?.data];
        //   return newPartnershipData;
        // });
        setCurrentPartnershipData((prevPartnership) => {
          const existingIndex = prevPartnership.findIndex(item => item.commentaryPartnershipId === partnership?.data.commentaryPartnershipId);
          if (existingIndex === -1) {
            return [...prevPartnership, partnership?.data];
          } else {
            return prevPartnership;
          }
      });
      } else if (partnership?.type === "delete") {
        setCurrentPartnershipData((prevPartnership) => {
          const updatedPartnershipData = partnership?.data;
          const newPartnershipData = prevPartnership.filter((item)=> item.commentaryPartnershipId !== updatedPartnershipData.commentaryPartnershipId);
          return newPartnershipData;
        });
      }
      if(marketOddsBallByBall?.type === "full"){
        setMarketOddsBallByBallData(marketOddsBallByBall?.data);
      } else if (marketOddsBallByBall?.type === "create") {
        setMarketOddsBallByBallData((prevMarket) => {
          const newMarketData = marketOddsBallByBall?.data;
          const updateMarket = [...prevMarket];
          newMarketData.forEach((newMarket)=>{
            const existingIndex = updateMarket.findIndex(item => item.id === newMarket?.id);
            if (existingIndex === -1) {
              updateMarket.push(newMarket)
            }
          });
          return updateMarket
      });
      }
      }
    });
  }

  const fetchInningData = async (inningDataPromises) => {
    try {
      const inningsData = await Promise.all(inningDataPromises);      
      inningsData.forEach((inningData, index) => {
        allCommentaryInnings["cci" + (index + 1)] = inningData;
      });
      // setAllCommentaryInnings(allCommentaryInnings);
    } catch (error) {
      console.error("Error in Promise.all:", error);
    }
  };
  useEffect(() => {
    const team1 = currentTeamsData.find(
      (item) => item?.teamId === currentDetailsData?.team1Id && item?.currentInnings === currentDetailsData?.currentInnings
    );
    const team2 = currentTeamsData.find(
      (item) => item?.teamId === currentDetailsData?.team2Id && item?.currentInnings === currentDetailsData?.currentInnings
    );
    const team3 = currentTeamsData.find(
      (item) => item?.teamId === currentDetailsData?.team1Id && item?.currentInnings !== currentDetailsData?.currentInnings
    );
    const team4 = currentTeamsData.find(
      (item) => item?.teamId === currentDetailsData?.team2Id && item?.currentInnings !== currentDetailsData?.currentInnings
    );
    const squad1 = [];
    const squad2 = [];
    if (currentPlayersData) {
      currentPlayersData.forEach((player) => {
        if (player?.teamId === team1?.teamId) {
          squad1.push(player);
        } else if (player?.teamId === team2?.teamId) {
          squad2.push(player);
        }
      });
    }
    const battingTeam = currentTeamsData?.find(
      (team) =>
        team.teamStatus === 1 &&
        currentDetailsData?.currentInnings === team?.currentInnings
    );
    const prevBattingTeam = currentTeamsData?.find(
      (team) =>
        team.teamStatus === 1 &&
        currentDetailsData?.currentInnings !== team?.currentInnings
    );
    const bowlingTeam = currentTeamsData?.find(
      (team) =>
        team.teamStatus === 2 &&
        currentDetailsData?.currentInnings === team?.currentInnings
    );
    const prevBowlingTeam = currentTeamsData?.find(
      (team) =>
        team.teamStatus === 2 &&
        currentDetailsData?.currentInnings !== team?.currentInnings
    );
    if (parseInt(currentDetailsData?.commentaryStatus) === 1 || parseInt(currentDetailsData?.commentaryStatus) === 4) {
    setScoreData({
      ...scoreData,
      cst: currentDetailsData?.commentaryStatus,
      scot: battingTeam?.shortName,
      utc: currentDetailsData?.eventDate,
      com: currentDetailsData?.competition,
      crr: battingTeam?.crr,
      rrr: battingTeam?.rrr,
      t1n: team1?.teamName,
      t2n: team2?.teamName,
      t3n: team3?.teamName,
      t4n: team4?.teamName,
      t1im: team1?.image,
      t2im: team2?.image,
      t3im: team3?.image,
      t4im: team4?.image,
      t1sn: team1?.shortName,
      t2sn: team2?.shortName,
      t3sn: team3?.shortName,
      t4sn: team4?.shortName,
      t3Id: team3?.teamId,
      t4Id: team4?.teamId,
      t1MatchDetails: getMatchDetails(teamScoreData(team1)),
      t2MatchDetails: getMatchDetails(teamScoreData(team2)),
      t3MatchDetails: teamScoreData(team3),
      t4MatchDetails: teamScoreData(team4),
      res: currentDetailsData?.result,
      loc: currentDetailsData?.location,
      mtyp: currentDetailsData?.matchType,
      // tpp1: currentDetailsData?.tpp1,
      // tpp2: currentDetailsData?.tpp2,
    });
    }
    const currentBowlingTeam = currentTeamsData?.find(
      (team) =>
        team.teamStatus === 1 &&
        currentDetailsData?.currentInnings === team?.currentInnings
    );
    setIsChase(currentBowlingTeam?.teamBattingOrder === 2);
    if (currentBallByBallData && currentOversData) {
      currentOversData.forEach((over) => {
        if (!over.ball) {
          over.ball = [];
        }
      });
      currentBallByBallData.forEach((ball) => {
        const over = currentOversData.find((o) => o.overId === ball.overId);
        if (over) {
          const existingBallIndex = over.ball.findIndex((b) => b.commentaryBallByBallId === ball.commentaryBallByBallId);
          if (existingBallIndex === -1) {
            over.ball.push(ball);
          } 
          // over.ball.push(ball);
        }
      });
      currentOversData.sort((a, b) => b.overId - a.overId);
    }
    const separatedArrays = currentOversData && currentOversData.reduce((acc, obj) => {
        const { teamId } = obj;
        if (!acc[teamId]) {
          acc[teamId] = [];
        }
        acc[teamId].push(obj);
        return acc;
      }, {});
    const { batTeams, bowlTeams } = getSocketTeamData(
      currentTeamsData,
      battingTeam?.teamId
    );
    
    const inningDataPromises = [];

    const getInningDataByInningNumber = (inningNumber) =>{
      const currentBattingTeam = currentTeamsData?.find(
        (team) =>
          team.teamStatus === 1 &&
          team?.currentInnings === inningNumber
      );
      const currentBowlingTeam = currentTeamsData?.find(
        (team) =>
          team.teamStatus === 2 &&
          team?.currentInnings === inningNumber
      );
      
      const getBattingCompleted = currentTeamsData.find(
        (item) =>
          item.currentInnings === inningNumber &&
          item.isBattingComplete === true
      );
      const commentaryInnings = {
        bat1: currentPlayersData.filter(
                (item) =>
                  item.teamId === currentBattingTeam?.teamId &&
                  item.currentInnings === inningNumber &&
                  (item.onStrike !== null || item.isBatterOut === true) && item.batterOrder !== null
              ),
        bat2: getBattingCompleted ? currentPlayersData.filter(
                (item) =>
                  item.teamId === currentBowlingTeam?.teamId &&
                  item.currentInnings === inningNumber &&
                  (item.onStrike !== null || item.isBatterOut === true) && item.batterOrder !== null
              ) : [],
        bow1: getBattingCompleted ? currentPlayersData.filter(
                (item) =>
                  item.teamId === currentBattingTeam?.teamId &&
                  item.currentInnings === inningNumber && item.bowlerOrder !== null
              ) : [],
        bow2: currentPlayersData.filter(
                (item) =>
                  item.teamId === currentBowlingTeam?.teamId &&
                  item.currentInnings === inningNumber &&
                  (item.bowlerTotalBall !== null || item.isPlay == true) && item.bowlerOrder !== null
              ),
        fow1: currentWicketData.filter(
          (item)=> 
            item.teamId === currentBattingTeam?.teamId &&
            item.currentInnings === inningNumber
          ),
        fow2: getBattingCompleted ? currentWicketData.filter(
          (item)=> 
            item.teamId === currentBowlingTeam?.teamId &&
            item.currentInnings === inningNumber
          ) : [],
      };
      return commentaryInnings
    }
    for (let i = 1; i <= currentDetailsData.currentInnings; i++) {
      inningDataPromises.push(
        getInningDataByInningNumber(i)
      );
    }
    fetchInningData(inningDataPromises);
    setOversData({
      overs: separatedArrays,
      teamsData: currentTeamsData,
      players: currentPlayersData,
      summery: currentDetailsData,
    });
    setGraphData({
      teamsData: currentTeamsData,
      oversData: currentOversData,
      summery: currentDetailsData,
    });
    setScoreCardData({
      td: batTeams,
      es: currentDetailsData,
      te1n: team1?.teamName,
      te2n: team2?.teamName,
      te3n: team3?.teamName,
      te4n: team4?.teamName,
      team1Id: team1?.teamId,
      team2Id: team2?.teamId,
      team3Id: team3?.teamId,
      team4Id: team4?.teamId,
      t1s: teamScoreData(team1),
      t2s: teamScoreData(team2),
      t3s: teamScoreData(team3),
      t4s: teamScoreData(team4),
      cci: allCommentaryInnings,
      sqt1: squad1,
      sqt2: squad2,
      t1id: team1?.commentaryTeamId,
      t2id: team2?.commentaryTeamId,
      t3id: team3?.commentaryTeamId,
      t4id: team4?.commentaryTeamId,
      baid: battingTeam?.teamId,
      boid: bowlingTeam?.teamId,
      prevBaid: prevBattingTeam?.teamId,
      prevBoid: prevBowlingTeam?.teamId,
      t1jr: team1?.jersey,
      t2jr: team2?.jersey,
      t3jr: team3?.jersey,
      t4jr: team4?.jersey,
    });
    setTeamData({
      te1n: team1?.teamName,
      te2n: team2?.teamName,
      t1jr: team1?.jersey,
      t2jr: team2?.jersey,
      sqt1: squad1,
      sqt2: squad2,
      t1img: team1?.image,
      t2img: team2?.image,
      es: currentDetailsData,
    });
    setPartnersipData({
      par: currentPartnershipData,
      t1jr: team1?.jersey,
      t2jr: team2?.jersey,
      td: batTeams,
    });
    setCommentaryOverData({
      td: bowlTeams,
      ov: currentOversData,
      t1s: teamScoreData(team1),
      t2s: teamScoreData(team2),
      sqt1: squad1,
      sqt2: squad2,
      t1id: team1?.commentaryTeamId,
      t2id: team2?.commentaryTeamId,
      t3id: team3?.commentaryTeamId,
      t4id: team4?.commentaryTeamId,
      team1Id: team1?.teamId,
      team2Id: team2?.teamId,
      team3Id: team3?.teamId,
      team4Id: team4?.teamId,
      teamsData: currentTeamsData,
      marketData: marketOddsBallByBallData
    });
  }, [currentBallByBallData, currentDetailsData, currentOversData, currentPartnershipData, currentPlayersData, currentTeamsData, marketOddsBallByBallData]);

  useEffect(() => {
    if (paramsData?.id && inProgress && socket) {
      if (socket?.connected) {
        configSocket(paramsData?.id);
      } else {
        socket.on(CONNECT, () => {
          configSocket(paramsData?.id);
        });
      }
    }
  }, [paramsData?.id, inProgress]);

  useEffect(() => {
    if (paramsData?.id && socket) {
      if (socket?.connected) {
        fullscoreConfigSocket(paramsData?.id);
      } else {
        socket.on(CONNECT, () => {
          fullscoreConfigSocket(paramsData?.id);
        });
      }
    }
  }, [paramsData?.id]);

  useEffect(() => {
    if (paramsData?.id && socket) {
      if (socket?.connected) {
        runnerSocket(paramsData?.id);
      } else {
        socket.on(CONNECT, () => {
          runnerSocket(paramsData?.id);
        });
      }
    }
  }, [paramsData?.id]);

  // useEffect(() => {
  //   setLoading(true);

  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [showState]);

  return (
    <div class="col-lg-9">
      {loading ? (
        <FullScoreContentSkeleton />
      ) : (
        <>
         <FullScoreContent
              scoreData={scoreData}
              runnerData={runnerData}
              inProgress={inProgress}
              isChase={isChase}
            />
          {/* {!isError && scoreData ? (
            <FullScoreContent
              scoreData={scoreData}
              inProgress={inProgress}
              isChase={isChase}
            />
          ) : (
            <div class="card card-shadow p-0">
              <div className="score-card-inner flex-grow-1 px-20 py-20 text-center">
                <strong> There are no matches now </strong>
              </div>
            </div>
          )} */}
        </>
      )}
      {/* {(scoreData?.team1Runner && scoreData?.team2Runner) && <div className="card card-shadow py-10 px-10">
        <div className="d-md-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center my-2">
              <div className="runner-team mr-2">{scoreData?.t1n}</div>
              <div className="d-flex align-items-center mx-2">
                  <div className="back-price">{scoreData?.team1Runner?.bp}</div>
                  <div className="lay-price">{scoreData?.team1Runner?.lp}</div>
              </div>
          </div>
          {scoreData?.drawRunner &&
          <div className="d-flex align-items-center my-2">
              <div className="runner-team mr-2">Draw</div>
              <div className="d-flex align-items-center mx-2">
                  <div className="back-price">{scoreData?.drawRunner?.bp}</div>
                  <div className="lay-price">{scoreData?.drawRunner?.lp}</div>
              </div>
          </div>}
          <div className="d-flex align-items-center my-2">
              <div className="runner-team mr-2">{scoreData?.t2n}</div>
              <div className="d-flex align-items-center mx-2">
                  <div className="back-price">{scoreData?.team2Runner?.bp}</div>
                  <div className="lay-price">{scoreData?.team2Runner?.lp}</div>
              </div>
          </div>
        </div>
      </div>} */}
      <section class="live-matches pt-0">
        <div class="card card-shadow">
          <ul class="nav nav-tabs mb-0">
            {(isShowClient && inProgress) && <li>
              <Link
                to={"#"}
                data-toggle="tab"
                className={showState === MATCH_SECTIONS.LIVE && "active"}
                onClick={() => setShowState(MATCH_SECTIONS.LIVE)}
              >
                Live
              </Link>
            </li>}
            {(isShowClient && (inProgress || currentDetailsData.commentaryStatus === 4)) && <li>
              <Link
                to={"#"}
                data-toggle="tab"
                className={showState === MATCH_SECTIONS.SCORECARD && "active"}
                onClick={() => setShowState(MATCH_SECTIONS.SCORECARD)}
              >
                Scorecard
              </Link>
            </li>}
            {(inProgress || currentDetailsData.commentaryStatus === 4) && <li>
              <Link
                to={"#"}
                data-toggle="tab"
                className={showState === MATCH_SECTIONS.COMMENTARY && "active"}
                onClick={() => setShowState(MATCH_SECTIONS.COMMENTARY)}
              >
                Commentary
              </Link>
            </li>}
            {<li>
              <Link
                to={"#"}
                data-toggle="tab"
                className={showState === MATCH_SECTIONS.TEAM && "active"}
                onClick={() => setShowState(MATCH_SECTIONS.TEAM)}
              >
                Team
              </Link>
            </li>}
            {(isShowClient && (inProgress || currentDetailsData.commentaryStatus === 4)) && <li>
              <Link
                to={"#"}
                data-toggle="tab"
                className={showState === MATCH_SECTIONS.PARTNERSHIP && "active"}
                onClick={() => setShowState(MATCH_SECTIONS.PARTNERSHIP)}
              >
                Partnership
              </Link>
            </li>}
            {(inProgress || currentDetailsData.commentaryStatus === 4) && <li>
              <Link
                to={"#"}
                data-toggle="tab"
                className={showState === MATCH_SECTIONS.OVERS && "active"}
                onClick={() => setShowState(MATCH_SECTIONS.OVERS)}
              >
                Overs
              </Link>
            </li>}
            {(inProgress || currentDetailsData.commentaryStatus === 4) && <li>
              <Link
                to={"#"}
                data-toggle="tab"
                className={showState === MATCH_SECTIONS.GRAPH && "active"}
                onClick={() => setShowState(MATCH_SECTIONS.GRAPH)}
              >
                Graph
              </Link>
            </li>}
          </ul>
        </div>
        {showState === MATCH_SECTIONS.LIVE && (
          <LiveScoreboard commentaryData={commentaryData} loading={loading}/>
        )}
        {(isShowClient && showState === MATCH_SECTIONS.SCORECARD) && (
          <ScoreCardDetails scoreCardData={scoreCardData} loading={loading}/>
        )}
        {showState === MATCH_SECTIONS.COMMENTARY && (
          <CommentaryDetails commentaryOverData={commentaryOverData} loading={loading} isShowClient={isShowClient}/>
        )}
        {showState === MATCH_SECTIONS.TEAM && <LiveTeams teamData={teamData} loading={loading}/>}
        {showState === MATCH_SECTIONS.PARTNERSHIP && (
          <PartnershipDetails partnershipData={partnershipData} loading={loading}/>
        )}
        {showState === MATCH_SECTIONS.OVERS && <Overs OversData={oversData} loading={loading} isShowClient={isShowClient}/>}
        {showState === MATCH_SECTIONS.GRAPH && <FullscoreChart graphData={graphData} loading={loading} />}
      </section>
    </div>
  );
};

export default FullScoreMainSection;
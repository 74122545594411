import { Link } from "react-router-dom";
import QuickLinks from "./QuickLinks";
import { useAppContext } from "../../Context/AppContext";
import { useEffect, useState } from "react";


const Footer = () => {
    const { cmsData } = useAppContext();
    const [blocksData, setBlocksData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (cmsData?.blocks?.length > 0) {
          setBlocksData(cmsData?.blocks || []);
          setLoading(false);
        }
        setLoading(false);
    }, [cmsData?.blocks]);
    return (
        <footer class="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="foo-widget foo-text mb-40">
                                <div class="foo-text-content">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        blocksData?.length > 0 ? (
                                            blocksData?.filter((item)=>item?.isShowContent)?.map(block => (
                                                <div key={block.blockId} dangerouslySetInnerHTML={{ __html: block.content }} />
                                            ))
                                        ) : null
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-md-6 col-lg-3">
                            <HotTopic />
                        </div> */}
                        <div class="col-md-6">
                            <QuickLinks />
                        </div>
                        {/* <div class="col-md-6 col-lg-3">
                            <Newsletter />
                        </div> */}
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="footer-copyright">
                    <div class="row">
                        <div class="col-md-6">
                            <p><Link to={"/#"}>Themeies</Link> © 2023 &nbsp;|&nbsp; All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
import React, { useEffect, useState } from "react";
import LineChart from "./LineChart";
import BarChart from "./BarChart";

const FullscoreChart = ({ graphData }) => {
  const [team1, setTeam1] = useState([]);
  const [team2, setTeam2] = useState([]);
  const [team3, setTeam3] = useState([]);
  const [team4, setTeam4] = useState([]);
  const [team1Color, setTeam1Color] = useState(null);
  const [team2Color, setTeam2Color] = useState(null);
  
  useEffect(() => {
    if (graphData?.summery && graphData?.oversData?.length > 0) {
      const team1Data = graphData?.oversData
        ?.sort((a, b) => a.overId - b.overId)
        ?.filter(
          (over) =>
            over.teamId === graphData?.summery.team1Id &&
            over.currentInnings === graphData?.summery?.currentInnings
        );      
      const team2Data = graphData?.oversData
        ?.sort((a, b) => a.overId - b.overId)
        ?.filter(
          (over) =>
            over.teamId === graphData?.summery.team2Id &&
            over.currentInnings === graphData?.summery?.currentInnings
        );
      const team3Data = graphData?.oversData
        ?.sort((a, b) => a.overId - b.overId)
        ?.filter(
          (over) =>
            over.teamId === graphData?.summery.team1Id &&
            over.currentInnings !== graphData?.summery?.currentInnings
        );
      const team4Data = graphData?.oversData
        ?.sort((a, b) => a.overId - b.overId)
        ?.filter(
          (over) =>
            over.teamId === graphData?.summery.team2Id &&
            over.currentInnings !== graphData?.summery?.currentInnings
        );
      const team1Color = graphData?.teamsData?.find((item)=>item.teamId === graphData?.summery.team1Id);
      setTeam1Color(team1Color?.teamColor);
      
      const team2Color = graphData?.teamsData?.find((item)=>item.teamId === graphData?.summery.team2Id);
      setTeam2Color(team2Color?.teamColor);
      
      setTeam1(team1Data || []);
      setTeam2(team2Data || []);
      setTeam3(team3Data || []);
      setTeam4(team4Data || []);
    }
  }, [graphData?.oversData, graphData?.summery, graphData?.teamsData]);

  return (
    <>
    <LineChart graphData={graphData} team1={team1} team2={team2} team3={team3} team4={team4} team1Color={team1Color} team2Color={team2Color} />
    <BarChart graphData={graphData} team1={team1} team2={team2} team3={team3} team4={team4} team1Color={team1Color} team2Color={team2Color} />
    </>
  );
};

export default FullscoreChart;
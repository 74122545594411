import React, { useEffect, useState, useRef } from "react";
import "./Scoreboard.css";
// import { io } from "socket.io-client";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  COMMENTARY_CONNECTION,
  CONNECT,
  DISCONNECT,
  EVENT_DATA,
  EVENT_RUNNER_DATA,
  RUNNER_CONNECTION,
} from "../utilities/const";
import socket from "../Features/socket";
import ScoreHeader from "../Components/Scoreboard/ScoreHeader";
import DetailScore from "../Components/Scoreboard/DetailScore";
import { getMatchDetails, getOvers, teamRunnerSocket } from "../utilities/functions";
import axiosInstance from "../Features/axios";

function Scoreboard() {
  let [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const eventId = searchParams.get("id");
  const [bowlers, setBowler] = useState([]);
  const [batsmen, setBatsmen] = useState([]);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [isApproved, setIsApproved] = useState(true);
  const [battingTeam, setBattingTeam] = useState({});
  const [bowlingTeam, setBowlingTeam] = useState({});
  const [percentageLeft, setPercentageLeft] = useState(null);
  const [percentageRight, setPercentageRight] = useState(null);
  const [percentageDraw, setPercentageDraw] = useState(null);
  const [runnerData, setRunnerData] = useState(null);
  const [marketRunnerData, setMarketRunnerData] = useState(null);
  const [isPer, setIsPer] = useState(false)
  const elemRef = useRef();
  const styleRef = useRef();
  // const navigate = useNavigate();

  useEffect(() => {
    const bgColor = searchParams.get("color");
    const fontColor = searchParams.get("font");
    styleRef.current = {
      backgroundColor: `#${bgColor}`,
      color: `#${fontColor}`,
    };
  }, [searchParams]);

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '334171585841519');
      fbq('track', 'PageView');
    `;
    
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-SCVQXEDJ8K`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-SCVQXEDJ8K');
  }, []);

  useEffect(() => {
    async function fetchData() {
      const iframes = window?.location?.ancestorOrigins;
      try {
        let urlString;
        if (iframes && iframes.length > 0) {
          const iframeLength = iframes.length;
          urlString = iframes[iframeLength - 1];
        } else {
          urlString = window.parent.location;
        }

        if (urlString) {
          var url = new URL(urlString);
          var domain = url.hostname;

          domain = domain
            .replace("https://www.", "")
            .replace("http://www.", "")
            .replace("https://", "")
            .replace("http://", "")
            .replace("www.", "")
            .replace("/", "");

          var parts = domain.split(".");
          const siteName = parts.slice(-2).join(".");
          const siteDomain = parts.slice(-2).join(".");
          const subdomains =
            parts.length > 2 ? parts.slice(0, parts.length - 2) : [];
          // const siteName = parts.length > 2 ? parts[1] : parts[0];

          const payload = {
            siteName: siteName,
            siteDomain: siteDomain,
            subDomains: subdomains,
          };

          const response = await axiosInstance.post(
            `/admin/domain/checkApprove`,
            payload
          );
          setIsApproved(response.data.result.isApproved);
        } else {
          console.error("Parent referrer URL is not available.");
        }
      } catch (error) {
        console.error("Error fetching parent domain:", error);
      }
    }
    const intervalId = setTimeout(fetchData, 5 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [eventId]);

  useEffect(() => {
    if (window.parent && window.parent.postMessage) {
      const height = isPer ? 140 : 110
      const elemHeight = elemRef?.current?.clientHeight || height;
      let curURL;
      const iframes = window?.location?.ancestorOrigins;
      if (iframes && iframes.length > 0) {
        const iframeLength = iframes.length;
        curURL = iframes[iframeLength - 1];
      } else if (window.parent.location) {
        curURL = window.location.hostname;
      } else {
        console.error("Unable to determine current URL.");
        return;
      }

      window.parent.postMessage(
        {
          scoreWidgetHeight: +elemHeight + 20,
          curURL: curURL,
        },
        "*"
      );
    }
  }, [eventId, isOpen, isPer]);

  const configSocket = () => {
    socket.emit(COMMENTARY_CONNECTION, [eventId]);
    socket.on(EVENT_DATA, (data) => {
      const eventData = data?.value;
      if (eventData) {
        setIsDataAvailable(true);
        let { cm, ...rest } = eventData;
        cm.t1md = getMatchDetails(cm.t1s);
        cm.t2md = getMatchDetails(cm.t2s);
        // const marketRunner = mr;
        cm.currOver = getOvers(cm.scov, eventData.cbb);
        const battingTeam = cm.scot === cm.t1sn ? { bgColor: cm?.t1bg, borderColor: cm?.t1co } : { bgColor: cm?.t2bg, borderColor: cm?.t2co }
        const bowlingTeam = cm.scot === cm.t2sn ? { bgColor: cm?.t1bg, borderColor: cm?.t1co } : { bgColor: cm?.t2bg, borderColor: cm?.t2co }
        // const team1runner = teamRunner(marketRunner, cm?.t1id, cm?.t1n);
        // const team2runner = teamRunner(marketRunner, cm?.t2id, cm?.t2n);
        // const draw = marketRunner?.length > 0 ? marketRunner.find((item)=> item.runner == "The Draw") : null;
        setBowler(eventData.cbl);
        setBatsmen(eventData.cbt);
        // setCurrentMatch({...eventData?.cm, team1Runner: team1runner, team2Runner: team2runner, drawRunner: draw});
        setCurrentMatch(eventData?.cm);
        // setPercentageLeft(parseFloat(eventData.cm?.tpp1));
        // setPercentageRight(parseFloat(eventData.cm?.tpp2));
        setIsPer(parseFloat(eventData.cm?.tpp1) && parseFloat(eventData.cm?.tpp2))
        setBattingTeam(battingTeam)
        setBowlingTeam(bowlingTeam)
      }
    });
    socket.on(DISCONNECT, (reason) => {
      console.log("socket disconnected", reason);
    });
  };

  const runnerSocket = () => {
    socket?.emit(RUNNER_CONNECTION, [eventId]);
    socket?.on(EVENT_RUNNER_DATA, (data) => {
      const eventRunnerData = data?.value;
      if (data?.eventId === eventId && eventRunnerData) {
        const marketRunner = eventRunnerData[0]?.runners;
        setMarketRunnerData(marketRunner)
      }
    });
  }
  
  useEffect(()=>{
    const team1runner = teamRunnerSocket(marketRunnerData, currentMatch?.t1id, currentMatch?.t1n);
    const team2runner = teamRunnerSocket(marketRunnerData, currentMatch?.t2id, currentMatch?.t2n);
    const draw = marketRunnerData?.length > 0 ? marketRunnerData.find((item)=> item.runner == "The Draw") : null;
    setRunnerData({
      team1Runner: team1runner,
      team2Runner: team2runner,
      drawRunner: draw,
    })
  },[marketRunnerData, currentMatch?.t1id, currentMatch?.t2id, currentMatch?.t1n, currentMatch?.t2n]);

  async function fetchScoreDataById() {
    try {
      const response = await axiosInstance.post(
        `/admin/match/getscoreByEId`,
        {
          eventId,
        }
      );
      const eventData = response?.data?.result;
      if (eventData) {
        setIsDataAvailable(true);
        let { cm, ...rest } = eventData;
        cm.t1md = getMatchDetails(cm.t1s);
        cm.t2md = getMatchDetails(cm.t2s);
        cm.currOver = getOvers(cm.scov, eventData.cbb);
        // const marketRunner = mr;
        const battingTeam = cm.scot === cm.t1sn ? { bgColor: cm?.t1bg, borderColor: cm?.t1co } : { bgColor: cm?.t2bg, borderColor: cm?.t2co }
        const bowlingTeam = cm.scot === cm.t2sn ? { bgColor: cm?.t1bg, borderColor: cm?.t1co } : { bgColor: cm?.t2bg, borderColor: cm?.t2co }
        // const team1runner = teamRunner(marketRunner, cm?.t1id, cm?.t1n);
        // const team2runner = teamRunner(marketRunner, cm?.t2id, cm?.t2n);
        // const draw = marketRunner?.length > 0 ? marketRunner.find((item)=> item.runner == "The Draw") : null;
        setBowler(eventData.cbl);
        setBatsmen(eventData.cbt);
        // setCurrentMatch({...eventData.cm, team1Runner: team1runner, team2Runner: team2runner, drawRunner: draw});
        setCurrentMatch(eventData?.cm);
        // setPercentageLeft(parseFloat(eventData.cm?.tpp1));
        // setPercentageRight(parseFloat(eventData.cm?.tpp2));
        setIsPer(parseFloat(eventData.cm?.tpp1) && parseFloat(eventData.cm?.tpp2))
        setBattingTeam(battingTeam)
        setBowlingTeam(bowlingTeam)
      }
    } catch (error) {
      console.error("Error fetching score by ID:", error);
    }
  }

  useEffect(()=>{
    if(currentMatch) {
      setPercentageLeft(parseFloat(currentMatch?.tpp1));
      setPercentageRight(parseFloat(currentMatch?.tpp2));
      setPercentageDraw(null);
      if(runnerData?.drawRunner?.runner === "The Draw" && currentMatch?.com == "Test Matches" && (parseFloat(currentMatch?.tpp1) + parseFloat(currentMatch?.tpp2)) < 100){
        const draw = 100 - (parseFloat(currentMatch?.tpp1) + parseFloat(currentMatch?.tpp2));
        setPercentageDraw(draw);
      } else if((parseFloat(currentMatch?.tpp1) && parseFloat(currentMatch?.tpp2)) && (parseFloat(currentMatch?.tpp1) + parseFloat(currentMatch?.tpp2)) < 100){
        const draw = 100 - (parseFloat(currentMatch?.tpp1) + parseFloat(currentMatch?.tpp2));
        if(parseFloat(currentMatch?.tpp1) > parseFloat(currentMatch?.tpp2)){
          setPercentageLeft(parseFloat(currentMatch?.tpp1) + draw)
        } else {
          setPercentageRight(parseFloat(currentMatch?.tpp2) + draw)
        }
      }
    }
  },[currentMatch?.tpp1, currentMatch?.tpp2]);

  useEffect(() => {
    if (eventId && isApproved) {
      fetchScoreDataById();
      if (socket) {
        if (socket?.connected) {
          configSocket();
        } else {
          socket.on(CONNECT, () => {
            configSocket();
          });
        }
        return () => {
          if (socket) {
            socket.disconnect();
          }
        };
      } else {
        const intervalTime = setInterval(
          fetchScoreDataById,
          process.env.API_INTERVAL || 3000
        );
        return () => {
          clearInterval(intervalTime);
        };
      }
    }

  }, [eventId, isApproved]);

  useEffect(() => {
    if (eventId && socket) {
      if (socket?.connected) {
        runnerSocket();
      } else {
        socket.on(CONNECT, () => {
          runnerSocket();
        });
      }
    }
  }, [eventId]);

  // useEffect(() => {
  //   const token = localStorage.getItem("accessToken");
  //   if (!token) {
  //     navigate('/login')
  //   }
  // }, []);

  return (
    eventId &&
    isDataAvailable && (
      <>
        {!isApproved && (
          <div className="message-container">
            <div className="trial-message">You are using trial version</div>
          </div>
        )}
        <div
          className="scoreboard"
          style={{ filter: !isApproved ? "blur(4px)" : "none" }}
        >
          <div
            className="scoreboard-2 new-scoreboard"
            id="divScore"
            ref={elemRef}
            style={{ height: isOpen ? "auto" : isPer ? "140px" : "110px" }}
          >
            <div className="score-header">
              <ScoreHeader currentMatch={currentMatch} styleRef={styleRef} battingTeam={battingTeam} bowlingTeam={bowlingTeam} percentageLeft={percentageLeft} percentageRight={percentageRight} percentageDraw={percentageDraw} runnerData={runnerData}/>
              <DetailScore
                currentMatch={currentMatch}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                batsmen={batsmen}
                bowlers={bowlers}
                styleRef={styleRef}
              />
            </div>
            <div
              className="scoreboard-2 ScorePridiction exceptionScoreboard-2"
              id="Pridiction"
            >
              <div className="score-header">
                <div className="center-block top-header-exception">
                  <div>
                    <span className="title title-exception"></span>
                  </div>
                  {/* <div className="progress progress-exception bar">
                    <div className="progressInnerDiv">
                      <div className="ProgressRight">
                        <span className="PredictionTeam1Name"></span>
                      </div>
                      <div className="ProgressRight">
                        <span className="PredictionTeam1Rate"></span>
                      </div>
                    </div>
                    <div className="ProgressCenter">
                      <div
                        className="progress-bar PredictionTeam1 ProgressCenterInnerDiv1"
                        role="progressbar"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        className="progress-bar PredictionTeam2 ProgressCenterInnerDiv2"
                        role="progressbar"
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div className="progressPosition">
                      <div className="ProgressLeft">
                        <span className="PredictionTeam2Name"></span>
                      </div>
                      <div className="ProgressLeft">
                        <span className="PredictionTeam2Rate"></span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="mobile-score-details hidden-lg hidden-sm exceptionScoreboard-2">
              <div className="more-tab-mobile">
                <div className="more-arrow" onClick={() => setIsOpen(!isOpen)}>
                  <div
                    className="down-arrow cursor-pointer"
                    id="mobile_details"
                  >
                    <img src="/assets/images/add-arrow.png" alt="add" />
                  </div>
                </div>
              </div>
              <table>
                <tbody>
                  <tr className="header" id="tblBatsManmScorecardlistbody">
                    <td>Batter</td>
                    <td className="center-align">R</td>
                    <td className="center-align">B</td>
                    <td className="center-align">4s</td>
                    <td className="center-align">6s</td>
                    <td className="center-align">SR</td>
                  </tr>

                  <tr
                    className="header bowler-header"
                    id="tblBowlermScorecardlistbody"
                  >
                    <td>Bowler</td>
                    <td className="center-align">O</td>
                    <td className="center-align">M</td>
                    <td className="center-align">R</td>
                    <td className="center-align">W</td>
                    <td className="center-align">ECO</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src={`https://www.facebook.com/tr?id=334171585841519&ev=PageView&noscript=1`}
            alt=""
          />
        </noscript>
      </>
    )
  );
}

export default Scoreboard;
